import React from 'react'
import { HomePage } from '../components'
import Layout from '../templates/layout'


const IndexPage = () => {  
  return (
    <Layout>
      <HomePage />
    </Layout>
  )
}

export default IndexPage
